<template>
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Selected Accessments </h4>
            <base-card-drop-down>
                <template #title>
                        Help
                </template>
                <template #default>
                    <a class="dropdown-item" href="#">
                            <i class="bi bi-arrows-move bs-icon-tiny" ></i>
                            Drag to re-order
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-hand-index bs-icon-tiny" ></i>
                        Double click to delete
                    </a>
                </template>
            </base-card-drop-down>
        </template>
      <template #default>
        <div class="row">


            <div class="col-12">
              <draggable
                v-model="list"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
                item-key="order"
                >
                <template #item="{ element }">
                  <li class="list-group-item">
                    <i class="bi bi-three-dots-vertical bs-icon-tiny" ></i>
                    {{ element.name }}
                  </li>
                </template>
              </draggable>
            </div>



        <div class="col-12 pt-2">
            <base-button btnColor="primary" > Save accessment config </base-button>
        </div>
      </div>
    </template>
  </base-card-plain>
</template>


<script>
import draggable from "vuedraggable";


export default {
  components: {
    draggable
  },
  data() {
    return {
      list: [
        { name: "Test", id: 0 },
        { name: "Interview", id: 1 }
      ],
      drag: false
    };
  },
  methods: {
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
};
</script>

<style scoped>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
