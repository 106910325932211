<template>


    <!-- BEGIN: Content-->
    <div class="content-body">
        <!-- full job description -->
        <!-- Bordered table start -->
        <div class="row" id="table-bordered">
            <div class="col-12">
                <base-card-plain :showFooter="false">
                    <template #header>
                        <div class="col-3 col-md-3 col-lg-3 col-sm-12 mb-1">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Add city, country or continents " aria-describedby="button-addon2" />
                            </div>
                        </div>
                        <base-card-drop-down>
                            <template #title>
                                Options
                            </template>
                            <template #default>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-check bs-icon-tiny" ></i>
                                    All
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-x bs-icon-tiny" ></i>
                                    Active
                                </a>

                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-pencil bs-icon-tiny" ></i>
                                    Inactive
                                </a>
                            </template>
                        </base-card-drop-down>
                    </template>
                    <template #default>

                            <table class="table table-bordered table-responsive">
                                <thead>
                                    <tr>
                                        <th>Accessment</th>
                                        <th class="tiny-td-col">Status</th>
                                        <th class="tiny-td-col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr class="table-hover">
                                        <td>
                                            <span class="fw-bold">Angular Project</span>
                                        </td>
                                        <td><span class="badge rounded-pill badge-light-primary me-1">Active</span></td>
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots bs-icon-small" ></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-pencil-square bs-icon-small" ></i>
                                                        <span> Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-archive bs-icon-small" ></i>
                                                        <span> Archive</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-trash bs-icon-small" ></i>
                                                        <span> Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="table-hover">
                                        <td>
                                            <span class="fw-bold">React ewProject</span>
                                        </td>
                                        <td><span class="badge rounded-pill badge-light-success me-1">Active</span></td>
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots bs-icon-small" ></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-pencil-square bs-icon-small" ></i>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-archive bs-icon-small" ></i>
                                                        <span>Archive</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-trash bs-icon-small" ></i>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="table-hover">
                                        <td>
                                            <span class="fw-bold">Vuejs Project</span>
                                        </td>
                                        <td><span class="badge rounded-pill badge-light-info me-1">Inactive</span></td>
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots bs-icon-small" ></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-pencil-square bs-icon-small" ></i>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-archive bs-icon-small" ></i>
                                                        <span>Archive</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-trash bs-icon-small" ></i>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="table-hover">
                                        <td>
                                            <span class="fw-bold">Bootstrap Project</span>
                                        </td>
                                        <td><span class="badge rounded-pill badge-light-warning me-1">active</span></td>
                                        <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i class="bi bi-three-dots bs-icon-small" ></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-pencil-square bs-icon-small" ></i>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-archive bs-icon-small" ></i>
                                                        <span>Archive</span>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="bi bi-trash bs-icon-small" ></i>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <base-pagination>
                            </base-pagination>
                    </template>
                </base-card-plain>
            </div>
        </div>
        <!-- Bordered table end -->
        <!-- full job description -->



    </div>
    <!-- END: Content-->


</template>
