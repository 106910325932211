<template>


    <!-- BEGIN: Content-->
    <create-job-accessment-config>
    </create-job-accessment-config>

    <recruitment-sort-accessments>
    </recruitment-sort-accessments>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex

import CreateJobAccessmentConfig from '@/components/forms/recruitment/CreateJobAccessmentConfig.vue';
import RecruitmentSortAccessments from '@/components/recruitment/RecruitmentSortAccessments.vue';

export default{
    components:{
        CreateJobAccessmentConfig,
        RecruitmentSortAccessments
    }
}


</script>
